function formatCity (city) {
  if (city.loading) return city.text;

  var city_str = city.title;
  if (city.area) {
    city_str += ', ' + city.area;
  }
  if (city.region) {
    city_str += ', ' + city.region;
  }

  var markup = city_str;

  return city_str;
}
function formatCitySelection (city) {
  return city.title || city.text;
}

function initialize_order_page($) {
  var $select2 = $('.city-select2');
  var $deliveryMethods = $('.js-delivery');

  $(document).on('click', '.js-tabs li', function(event) {
    event.preventDefault();

    var $this = $(this);
    var $target = $this.data('target');

    $('.js-tabs li').removeClass('active');
    $this.addClass('active');

    $('.js-content > *').addClass('hidden');
    $($target).removeClass('hidden').find('.provider-tr').first().click();

    validate($('.validate_block.contacts'))
  });
  $('.js-tabs li.active').click();

  $select2.select2({
    ajax: {
      url: $select2.data('url'),
      dataType: 'json',
      delay: 250,
      data: function (params) {
        return {
          q: params.term,
          offset: params.page ? (params.page - 1) * 8 : 0
        };
      },
      processResults: function (data, params) {
        params.page = params.page || 1;
        errorPaymentText();
        return {
          results: data.response.items,
          pagination: {
            more: params.page * 8 < data.response.count
          }
        };
      },
      cache: true
    },
    placeholder: "Выберите город",
    minimumInputLength: 1,
    escapeMarkup: function(markup) {
      return markup;
    },
    templateResult: formatCity,
    templateSelection: formatCitySelection,
    dropdownCssClass: 'select2_dropdown'
  });
  $deliveryMethods.find('select:not(.hidden)').select2({
    dropdownCssClass: 'select2_dropdown',
    placeholder: 'Выберите пункт выдачи'
  });
  $('.new-select.infinity-select:not(.hidden)').select2({
    dropdownCssClass: 'select2_dropdown',
    minimumResultsForSearch: Infinity
  });

  $(".postcode-mask-input").inputmask("999999");
  $select2.change(function(e) {
    show_loader();
    var url = $deliveryMethods.attr('data-url').replace('0', $select2.val());
    $deliveryMethods.load(url, '', function(data, textStatus, XMLHttpRequest) {
      $deliveryMethods.find('select:not(.hidden)').select2({
        dropdownCssClass: 'select2_dropdown',
        placeholder: 'Выберите пункт выдачи'
      });
      $('.js-tabs li.active').click();
      $(".postcode-mask-input").inputmask("999999");
      hide_loader();
      checkOrderMinPrice();
    });
  });

  function checkOrderMinPrice() {
    var can_order = $deliveryMethods.find('.order_state').data('can_order') !== 'False';
    var order_min_sum = $deliveryMethods.find('.order_state').data('order_min_sum');
    if (!can_order) {
      $('.order_disable .order_min_sum').text(order_min_sum + '\u00A0руб.');
      $('.order_disable').removeClass('hidden');
      $('.total_payment .submit, .order-payment .submit').attr('disabled', 'disabled');
    } else {
      $('.order_disable').addClass('hidden');
      $('.total_payment .submit, .order-payment .submit').removeAttr('disabled');
    }
  }

  function initial_set_payment() {
    var provider_checked = $('.delivery-provider:checked');
    var provider_checked_tr = provider_checked.closest('tr');
    var prepayment_table = provider_checked_tr.next().find('.prepayment-table');
    if (prepayment_table.length) {
      var checked_prepayment = prepayment_table.find('.prepayment:checked');
      var deliveryCost = checked_prepayment.val() || 0;
    } else {
      var deliveryCost = provider_checked_tr.find('.price').html() || 0;
    }
    change_order_costs(deliveryCost);
  }
  initial_set_payment();

  $deliveryMethods.on('click', '.provider-tr', function(event) {
    var input = $(this).find('.delivery-provider');

    $('.js-content .delivery-provider').attr('checked', false);

    input.attr('checked',true);
    input.trigger('change');
    set_provider(input);
    $(this).next().find('.prepayment-table tr:first').click();

    validate($('.validate_block.contacts'))
  });

  $deliveryMethods.on('click', '.prepayment-table tr', function(event) {
    var $this = $(this);
    $this.closest('table').find('tr').removeClass('active');
    $this.addClass('active');

    $('.js-content .delivery-provider').attr('checked', false);

    var input = $this.find('input.prepayment');
    input.attr('checked',true);
    input.trigger('change');
    var deliveryCost = input.val() || 0;
    change_order_costs(deliveryCost);
  });

  function set_provider($this) {
    var this_tr = $this.closest('tr');
    $('.provider-tr').removeClass('active');
    this_tr.addClass('active');
    var current_data_fields = this_tr.next();
    $('.delivery-data-fields').addClass('hidden');
    $('.delivery-data-field').removeAttr('required').attr('name', '');
    current_data_fields.removeClass('hidden');
    current_data_fields.find('.delivery-data-field').each(function() {
      $(this).attr('name', $(this).attr('data-original-name'));
      $(this).not('.no-required').attr('required', 'required');
    });
    var prepayment_table = this_tr.next().find('.prepayment-table');
    if (prepayment_table.length) {
      var checked_prepayment = prepayment_table.find('.prepayment:checked');
      var deliveryCost = checked_prepayment.val() || 0;
    } else {
      var deliveryCost = this_tr.find('.price').html() || 0;
    }
    change_order_costs(deliveryCost);
  }

  $deliveryMethods.on('change', '.provider-select', function() {
    var $this = $(this).find('option:selected');
    var point_info = $this.closest('td').find('.point-info');
    var acquiring = $this.attr('data-acquiring');
    point_info.find('.work-schedule').text($this.attr('data-work-schedule'));
    point_info.find('.point-payment-type').addClass('hidden');
    point_info.find('.point-payment-type.acquiring-' + acquiring).removeClass('hidden');
    point_info.removeClass('hidden');
  });

  $deliveryMethods.on('keyup', '.postcode-mask-input', function() {
    var $this = $(this);
    var need_refresh_after_postcode = $this.parent().hasClass('need-postcode');
    if (need_refresh_after_postcode) {
      var provider_code = $('tbody').find('.active').find('.delivery-provider').attr('data-provider-code');
      if (parseInt($this.val()).toString().length === 6) {
        show_loader();
        var url = $deliveryMethods.attr('data-url').replace('0', $select2.val());
        url = url + '?address_postcode=' + $this.val();
        url = url + '&delivery_provider_code=' + $this.parent().attr('data-provider-code');
        $deliveryMethods.load(url, '', function(data, textStatus, XMLHttpRequest) {
          var deliveryCost = $deliveryMethods.find('.delivery-provider:checked').closest('tr').find('.price').html() || 0;
          change_order_costs(deliveryCost);
          $('.js-tabs li.active').click();
          $deliveryMethods.find('select:not(.hidden)').select2({
            dropdownCssClass: 'select2_dropdown',
            placeholder: 'Выберите пункт выдачи'
          });
          $(".postcode-mask-input").inputmask("999999");
          hide_loader();
        });
      }
    }
  });

  $deliveryMethods.on('change', '.js-delivery-point-radio', function (event) {
    var providerCode = $(this).closest('.popup').data('provider-code');
    var text = $(this).closest('label').text().trim();
    $(this).closest('.popup').find('.close').click();
    $('#id_provider_' + providerCode).closest('tr').find('.js-point-select-link').html(text);
  });

  function change_order_costs(delivery_price) {
    if (delivery_price === 'бесплатно' || delivery_price === 'Бесплатно') {
      $('.total_product_price .coast').text(delivery_price);
    } else {
      $('.total_product_price .coast').text(delivery_price + ' руб.');
    }
    $('.results .delivery-cost').text(delivery_price);
    $('#order-delivery-cost').val(delivery_price);
    if (delivery_price !== 'Введите индекс') {
      delivery_price = parseInt(delivery_price) || 0;
      delivery_price = parseInt($('.final_price .coast').attr('data-cost-without-delivery')) + delivery_price;
    }
    $('.final_price .coast .sum').text(delivery_price);
  }
}

function reinitialize_order_page() {
  jQuery(initialize_order_page);
}

function validate($elem) {

  if ($elem.prev().hasClass('validate_block') && !$elem.prev().hasClass('active')) {
    $elem.removeClass('active');
  } else {
    var count = $elem.find('.validate[name!=""]').length;
    var valid = $elem.find('.validate:valid').filter('[name!=""]').length;

    if (count === valid && count !== 0) {
      $elem.addClass('active');
    } else {
      $elem.removeClass('active');
    }
  }

  if ($elem.next().hasClass('validate_block')) {
    validate($elem.next());
  }
}

function errorPaymentText() {
  if ($('.city-select2').val() === '917' || $('.payment_block').find('.radio:checked').attr('id') !== 'cash') {
    $('.payment_block .error_text').addClass('hidden');
  } else {
    $('.payment_block .error_text').removeClass('hidden');
  }
}

$(document).ready(function() {
  if ($('.city-select2').length || $('.js-delivery').length || $('.js-tabs').length) {
    jQuery(initialize_order_page);
    create_ajax_form('#order-form-block', null, reinitialize_order_page);

    // var arr = [];
    // $.each($('.provider-select option'), function (i, e) {
    //   var gps = $(e).attr('data-gps');
    //
    //   if (typeof(gps) !== 'undefined') {
    //     gps = gps.split(',');
    //     arr.push(gps);
    //   }
    // });

    $(document).on('change', '.city-select2', function () {
      errorPaymentText();
      $('.delivery_city.open_popup .city').text($('#select2-city-select2-container').text())
    });

    $(document).on('click', '.select2', function (e) {
      e.stopPropagation();
      $('.select2-search__field').attr('autofocus', true).trigger('focus');
    });

    setTimeout(function () {
      $('.provider-tr').first().click();
      validate($('.validate_block.contacts'));
    }, 0);

    $(document).on('change', '.validate', function () {
      validate($('.validate_block.contacts'));
    });
    var validate_time_id = null;
    $(document).on('keyup', '.validate', function () {
      if (validate_time_id) {
        clearTimeout(validate_time_id);
      }
      validate_time_id = setTimeout(function () {
        validate($('.validate_block.contacts'));
      }, 300);
    });

    $(document).on('change', '.payment_block .radio', function () {
      $val = $('.payment_block').find('.radio:checked').attr('id');

      $('.order-payment .submit').addClass('hidden').removeClass('active');
      $('.payment_buttons .submit[data-name=' + $val + ']').removeClass('hidden').addClass('active');

      if ($val === 'cash' && !$('.provider-tr.papara').length) {
        $('.payment_block .error_text').removeClass('hidden')
      } else {
        $('.payment_block .error_text').addClass('hidden')
      }
    });

    errorPaymentText();

    $(document).on('click', '.total_payment .submit, #form_order .submit', function (e) {
      if (!$(this).hasClass('active')) {
        e.preventDefault();
        $('.payment_buttons .submit.active').click();
      }

      $('#form_order .validate').each(function(index, element){
        if (!$(this).val().length > 0 && $(this).attr('name') !== '') {
          $(this).addClass('error');
        } else {
          $(this).removeClass('error');
        }
      });

      if ($('input').hasClass('error') || $('select').hasClass('error')) {
        var destination = $('.error').first().offset().top;
        $('html, body').animate({scrollTop:destination  - 180},400);
      }
    })
  }
});