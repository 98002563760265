photo_scale_editor = null;

function get_photo_scale_editor_url(current_url) {
  var url = current_url;
  var index_find = url.indexOf('?editor_scale=');
  var editor_scale_string = '?editor_scale=' + photo_scale_editor;
  if (index_find === -1) {
    url += editor_scale_string
  } else {
    url = url.slice(0, index_find) + editor_scale_string;
  }
  return url;
}

function set_dropzone_url() {
  url = get_photo_scale_editor_url($("#device-dropzone").attr('action'));
  $("#device-dropzone").attr('action', url);
  uploader.options.url = url;
}

function resizable_img() {
  var imgHeight = '',
      imgWidth = '',
      parentHeight = '',
      parentWidth = '',
      $this = $('.photo_list_block').not('.plus, .hidden'),
      $photoItem = $this.find('.photo_list_item:nth-of-type(2)');

  if (!$photoItem.length) {
    return;
  }

  if ($this.hasClass('square_img_block')) {
    parentWidth = $photoItem.find('.img_wrapper').width();
    imgWidth = $photoItem.find('img').width();

    if (parentWidth < imgWidth) {
      $this.removeClass('square_img_block');
    }
  } else {
    parentHeight =$photoItem.find('.img_wrapper').height();
    imgHeight = $photoItem.find('img').height();

    if (parentHeight < imgHeight) {
      $this.addClass('square_img_block');
    }
  }
}

//        DROPZONE
var counter = 0;
if (typeof Dropzone !== "undefined" && $("#device-dropzone").length) {
  Dropzone.autoDiscover = false;
  uploader = new Dropzone("#device-dropzone", {
    dictDefaultMessage: $('#add-photo-from-device .empty').html(),
    thumbnailWidth: 190,
    thumbnailHeight: 150,
    acceptedFiles: 'image/*',
    parallelUploads: 6
  });
  uploader.on("success", function (file, responseText) {
    var img_object = $('img', file.previewElement);
    img_object.attr('data-orig-src', responseText.img_src);
    img_object.attr('data-orig-name', responseText.orig_name);
    img_object.attr('data-user-photo-id', responseText.user_photo_id);
    img_object.attr('data-print-src', responseText.original_src);
    img_object.attr('data-preview-src', responseText.preview_src);
    img_object.attr('data-col', responseText.preview_grid_count);
    img_object.attr('data-orientation', responseText.orientation);
    counter++;
    $('.waiting_text .count').text(counter)
  });
  uploader.on("addedfile", function (file) {
    $("#add-photo-from-device").find(".ok").addClass('inactive');
    $(".waiting_text").removeClass("hidden");
    $('.waiting_text .count_all').text($('#device-dropzone .dz-preview').length);
    $(".label_select_all").addClass("hidden");
    $(".download_more_link").removeClass("hidden");
    $('#device-dropzone').animate({scrollTop: 10000},400);
  });
  uploader.on("queuecomplete", function () {
    $("#add-photo-from-device").find(".ok").removeClass('inactive');
    $(".waiting_text").addClass("hidden");
    $(".label_select_all").removeClass("hidden");
  });
}

$(document).ready(function() {
  if ($('.add_photo').length) {
    $(document).on('click', '.open_popup', function () {
      send_metrika_goal('EDITOR_OPEN_ADD_PHOTO_POPUP');
    });
    $(document).on('click', '.download_more_link', function () {
      send_metrika_goal('EDITOR_DOWNLOAD_MORE_CLICK');
    });

    //        ВЫБОР МЕТОДА ЗАГРУЗКИ ФОТО В ПОПАПЕ
    $(document).on('click', '.method_list .method_link', function (e) {
      e.preventDefault;
      var all_methods = $('.method_list .method_link');
      var all_subpanels = $('.subpanel');
      var target_subpanel = $(this).attr('href');
      all_methods.removeClass('active');
      all_subpanels.addClass('hidden');
      $(this).addClass('active');
      $(target_subpanel).removeClass('hidden');
      var url_get_photos = $(this).attr('data-load-url');
      var id_loaded_block = $(this).attr('href');
      var params = '';
      if (url_get_photos) {
        show_loader($(target_subpanel));
        $(id_loaded_block).load(
          url_get_photos,
          params,
          function (data, textStatus, XMLHttpRequest) {
            hide_loader();
            if (textStatus == "error") {
              alert("Произошла ошибка при загрузке.\nОбновите страницу и попробуйте снова.");
            }
            page_product_list.jscroll(true, '.view_more_block');
          }
        );
      }
      return false;
    });
    $(document).on('click', '.view_more_link.instagram', function (e) {
      e.preventDefault();
      var $this = $(this);
      $this.addClass('active');
      $.ajax(
        {
          dataType: 'jsonp',
          type: 'get',
          url: $this.attr('href') + $this.data('max-id'),
          success: function (data) {
            if (data.photos.length) {
              $.each(data.photos, function (i, photo) {
                if (photo.images.standard_resolution.url) {
                  $('.fill.scroll .list').append(
                    '<li class="item size130 social not-loaded"><div class="img-wrapper">' +
                    '<img src="' + photo.images.thumbnail.url + '" alt="" data-orig-src="' +
                    photo.images.standard_resolution.url + '" class="photo-image">' +
                    '</div></li>'
                  )
                }
              });
              $this.data('max-id', data.photos[data.photos.length - 1].id)
            } else {
              $this.addClass('hidden');
            }
          },
          complete: function () {
            $this.removeClass('active');
          }
        }
      );
    });

    // Симуляция клика по Dropzone
    $(document).on('click', '.download_more_container', function () {
      $('#device-dropzone').click();
    });

    $('.add_photo_container').on('click', '.select_all label', function () {
      $(this).toggleClass('active');
      var subpanel = $(this).closest('.subpanel');
      var current_tab_id = $('.tab.active').attr('id');
      if ($(this).hasClass('active')) {
        var items = subpanel.find('.item, .dz-preview');
        items.addClass('active');
        items.not('.folder').not('.dz-error').each(function () {
          load_photo($(this), true);
        });
      } else {
        var items = subpanel.find('.item, .dz-preview');
        items.removeClass('active');
        $(".ok").addClass('inactive');
      }
      // return false;
    });
    $('.add_photo_container').on('click', '.item:not(.folder), .dz-preview.dz-success:not(.dz-error)', function () {
      load_photo($(this));
      // return false;
    });
    $(document).on('click', '.goto-album, .back-to-album', function () {
      var url_get_photos = $(this).attr('href');
      var loaded_block = $(this).closest('.subpanel');
      var params = '';
      if (url_get_photos) {
        show_loader($(loaded_block));
        loaded_block.load(
          url_get_photos,
          params,
          function (data, textStatus, XMLHttpRequest) {
            hide_loader();
            if (textStatus == "error") {
              alert("Произошла ошибка при загрузке.\nОбновите страницу и попробуйте снова.");
            }
          }
        );
      }
      return false;
    });
    function load_photo(this_item, only_activate) {
      if (only_activate) {
        this_item.addClass('active');
      } else {
        this_item.toggleClass('active');
      }
      this_item.removeClass('hover');
      var subpanel = this_item.closest('.subpanel');
      var ok_button = subpanel.find(".ok");
      var current_photo_list = this_item.parent();
      var select_all = $('.select-all', subpanel);
      var current_items = $('.item, .dz-preview', current_photo_list).not('.folder').not('.dz-error');
      var active_items = current_items.filter('.active');
      if (active_items.length) {
        if (!active_items.filter('.not-loaded').length) {
          ok_button.removeClass('inactive');
        } else {
          ok_button.addClass('inactive');
        }
      } else {
        ok_button.addClass('inactive');
      }
      if (current_items.not('.active').length) {
        select_all.text('Выделить все');
        select_all.removeClass('reverse');
      } else {
        select_all.text('Снять все');
        select_all.addClass('reverse');
      }
      if (this_item.hasClass('social') || this_item.hasClass('archive')) {
        if (this_item.hasClass('not-loaded')) {
          var progress = new CircularProgress({
            radius: 40,
            strokeStyle: '#fff',
            lineCap: 'round',
            lineWidth: 5,
            fillStyle: '#fff',
            text: {
              font: 'bold 14px gerberaregular'
            },
            initial: {
              strokeStyle: '#cccccc',
              lineWidth: 5
            }
          });
          this_item.append(progress.el);
          counter_progress = 0;
          progress.update(counter_progress);
          var progress_updater = setInterval(function () {
            counter_progress += 1;
            progress.update(counter_progress);
          }, 500);
          var photo_image = this_item.find('.photo-image');
          add_data = {
            'image_url': photo_image.attr('data-orig-src'),
            'margin': $('.add-photo-margin').val(),
            'proportion': $('.add-photo-proportion').val()
          }
          if (photo_image.attr('data-user-photo-id')) {
            add_data['user_photo_id'] = photo_image.attr('data-user-photo-id')
          }
          url = get_photo_scale_editor_url(this_item.closest('.list').attr('data-add-url'));
          $.ajax({
            url: url,
            type: 'POST',
            data: add_data,
            beforeSend: function (xhr) {
              xhr.setRequestHeader("X-CSRFToken", getCookie("csrftoken"));
            },
            success: function (data) {
              photo_image.attr('data-orig-name', data.orig_name);
              photo_image.attr('data-orig-src', data.img_src);
              photo_image.attr('data-user-photo-id', data.user_photo_id);
              photo_image.attr('data-print-src', data.original_src);
              photo_image.attr('data-preview-src', data.preview_src);
              photo_image.attr('data-col', data.preview_grid_count);
              photo_image.attr('data-orientation', data.orientation);
              this_item.removeClass('not-loaded');
              if (current_photo_list.find('.item.active.not-loaded').length == 0) {
                if (current_photo_list.find('.item:not(.active)').length == 0) {
                  select_all.text('Снять все');
                  select_all.addClass('reverse');
                }
                ok_button.removeClass('inactive');
              }
              progress.update(100);
              clearInterval(progress_updater);
            },
            error: function (request, status, error) {
              clearInterval(progress_updater);
              this_item.removeClass('active');
              alert("Произошла ошибка при добавлении изображения.\nОбновите страницу и попробуйте снова.");
            }
          });
        }
      }
    }

    $('.popup_container.add_user_photo').on('click', '.ok:not(.inactive)', function () {
      show_loader();
      send_metrika_goal('EDITOR_ADD_PHOTO_CLICK_OK');
      $('.help-add-photo').addClass('hidden');
      $('html').css('overflow', 'visible');
      $(this).closest('.popup_container').prev().removeClass('active_popup');
      var subpanel = $(this).closest('.subpanel');
      var current_items = $('.item.active, .dz-preview.active', subpanel).not('.folder').not('.dz-error');
      var active_tab = $('.page_add_photo .tab.active');
      var active_tab_id = active_tab.attr('data-tab');
      var parameters = $('.parameters[data-tab=' + active_tab_id + ']');
      var active_photo_list = active_tab.closest('.page_add_photo').find('.photo_list_block[data-tab=' + active_tab_id + ']');
      var souvenirs_photo_to_canvas_list = $('.uploaded-photo .photo-list');
      var current_paper = parameters.find("select.paper option:selected");
      var current_margins = parameters.find("select.margin option:selected");
      var current_correction = parameters.find("select.correction option:selected");
      var user_photo_pks = [];
      var save_in_editor_album_json = [];
      current_items.each(function () {
        var template_li = $('#template-photo-item').clone(true);
        var added_img = $('img', $(this));
        var new_img_in_list = $('img', template_li);
        active_photo_list.append(template_li);
        if (added_img.attr('data-preview-src')) {
          new_img_in_list.attr('src', added_img.attr('data-preview-src'));
          template_li.attr('data-col', added_img.attr('data-col'));
          template_li.addClass('col-' + added_img.attr('data-col'));
          template_li.addClass('user-photo-' + added_img.attr('data-user-photo-id'));
        } else {
          new_img_in_list.attr('src', added_img.attr('src'));
        }
        new_img_in_list.attr('data-orig-name', added_img.attr('data-orig-name'));
        new_img_in_list.attr('data-orig-src', added_img.attr('data-orig-src'));
        new_img_in_list.attr('data-user-photo-id', added_img.attr('data-user-photo-id'));
        new_img_in_list.attr('data-print-src', added_img.attr('data-print-src'));
        new_img_in_list.attr('data-orientation', added_img.attr('data-orientation'));
        var margin = false;
        if (current_margins.val() === 'on') {
          margin = true
        }
        new_img_in_list.attr('data-margin', margin);
        template_li.attr('data-item-url', added_img.attr('data-item-url'));
        user_photo_pks.push(added_img.attr('data-user-photo-id'));
        template_li.removeClass('hidden');
        template_li.removeAttr('id');
        if (template_li.hasClass('photo_list_item')) {
          var photo_info_block = $('.photo-info', template_li);
          $('.paper', photo_info_block).text(current_paper.text());
          $('.margins', photo_info_block).text(current_margins.text());
          $('.correction', photo_info_block).text(current_correction.text());
        }
        var template_photo_to_canvas = $('#template-photo-to-canvas').clone(true);
        souvenirs_photo_to_canvas_list.append(template_photo_to_canvas);
        template_photo_to_canvas.attr('src', added_img.attr('src'));
        template_photo_to_canvas.attr('data-orig-name', added_img.attr('data-orig-name'));
        template_photo_to_canvas.attr('data-orig-src', added_img.attr('data-orig-src'));
        template_photo_to_canvas.attr('data-print-src', added_img.attr('data-print-src'));
        template_photo_to_canvas.removeClass('hidden');
        template_photo_to_canvas.removeAttr('id');
        save_in_editor_album_json.push({
          "preview_src": added_img.attr('data-preview-src'),
          "print_src": added_img.attr('data-print-src'),
          "src": added_img.attr('data-orig-src')
        });
      });
      if (user_photo_pks.length) {
        $('.status_download_pannel').find('.status_config').addClass('active');
        var $photo_list_item = active_photo_list.find('.drop_area.photo_list_item');
        $photo_list_item.addClass('have_items');
        $photo_list_item.find('.with_item_text').removeClass('hidden');
        $photo_list_item.find('.without_item_text').addClass('hidden');
        var product_pk = current_paper.attr('data-product-pk');
        var add_data = {
          'product_pk': product_pk,
          'margin': current_margins.val(),
          'correction': current_correction.val(),
          'user_photo_pks': user_photo_pks,
        };
        $.ajax({
          url: active_tab.attr('data-add-item-url'),
          type: 'POST',
          data: add_data,
          beforeSend: function (xhr) {
            xhr.setRequestHeader("X-CSRFToken", getCookie("csrftoken"));
          },
          success: function (data) {
            $('.info_and_action .count_number').text(data.new_count);
            $('.info_and_action .price .cost').text(data.new_price);
            $('a.to_cart').removeClass('inactive');
            if (data.change_list.length) {
              for (var i = 0; i < data.change_list.length; i++) {
                new_item_param = data.change_list[i];
                var template_li = $('.photo_list_item.user-photo-' + new_item_param.item_user_photo_id);
                var current_paper_class = ' paper-' + current_paper.val();
                var current_margins_class = ' margins-' + current_margins.val();
                var current_correction_class = ' correction-' + current_correction.val();
                var new_classes = 'photo_list_item' + current_paper_class + current_margins_class + current_correction_class;
                template_li.removeClass().addClass(new_classes);
                template_li.attr('data-url', new_item_param.item_url);
                template_li.attr('data-item-id', new_item_param.item_id);
                var item_img = template_li.find('.photo-image');
                item_img.addClass('photo_list_item-' + new_item_param.item_id);
                item_img.attr('data-item-id', new_item_param.item_id);
                item_img.attr('data-url', new_item_param.item_url);
                template_li.find('.instruments .edit_photo').attr('href', new_item_param.item_url);
                if (new_item_param.preview_src_url) {
                  item_img.attr('src', new_item_param.preview_src_url);
                }
                if (new_item_param.low_quality) {
                  template_li.find('.icon.icon_error').removeClass('hidden');
                  template_li.find('.description.quality-warning .size_print').text(active_tab.data('size'));
                }
              }
              resizable_img();
            }
            send_metrika_goal('EDITOR_PHOTOPRINT_ADD_PHOTOS');
          },
          error: function (request, status, error) {
            alert("Произошла ошибка при добавлении изображения(й).\nОбновите страницу и попробуйте снова.");
          },
          complete: function () {
            hide_loader();
          }
        });
      }
      $('.sprite-editor-add-photo').click();
      setTimeout(function () {
        $('#device-dropzone .dz-preview').remove();
        $('.download_more_link').addClass('hidden');
        $('#device-dropzone').removeClass('dz-started');
        $('.popup_container.add_photo .method_link.device').click();
        counter = 0;
      }, 1000);
    });

    resizable_img();
    $(window).resize(function () {
      resizable_img();
    });
  }
});
