function show_loader($elem) {
  var $global_loader = $('#loader_container');
  if ($elem && $global_loader.hasClass('hidden')) {
    if ($elem.find('.loader_container').length) {
      $elem.find('.loader_container').removeClass('hidden');
    } else {
      var loader = $('<div class="loader_container"></div>');
      loader.append('<div class="loader_wrapper"><div class="loader_picture"></div></div>');
      loader.appendTo($elem);
    }
  } else {
    $global_loader.removeClass('hidden');
  }
}

function hide_loader($elem) {
  if ($elem) {
    $elem.find('.loader_container').addClass('hidden');
  }
  $('#loader_container').addClass('hidden');
}
