$(document).ready(function () {
  $(document).on('click', '.motive_slider .motive_item', function (e) {
    e.preventDefault();
    $(this).siblings('.motive_item').removeClass('active');
    $(this).addClass('active');

    show_loader($('#ajax_container'));

    $.ajax({
      url: $(this).attr('href'),
      method: 'GET',
      success: function (data) {
        $('#ajax_container').html(data)
      }
    })
  });

  $('.motive_slider .motive_item.slick-current').first().click();
});