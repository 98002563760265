$(document).ready(function () {
  if (!$('.pages_info_container.poll').length) return;
  $('.mystery_shopper_enter').on('submit', function(e) {
    e.preventDefault();
    var $form = $(this);
    $.ajax({
      url: $form.attr('action'),
      type: $form.attr('method'),
      data: $form.serialize(),
      success: function (res) {
        if (res.success) {
          $form.addClass('hidden');
          $('.mystery_shopper_poll').removeClass('hidden');
          $('#id_mystery_shopper').val(res.mystery_shopper_id);
        } else {
          alert('Ошибка');
        }
      }
    });
  });
  $('.mystery_shopper_poll').on('submit', function (e) {
    e.preventDefault();
    var $form = $(this);
    var json = {
      standards: []
    };
    $form.find('.standard').each(function() {
      var standard = {}
      standard.code1C = $(this).data('name');
      standard.done = $(this).prop('checked') ? 1 : 0;
      json.standards.push(standard);
    });
    $('#id_json').val(JSON.stringify(json));
    $.ajax({
      url: $form.attr('action'),
      type: $form.attr('method'),
      data: $form.serialize(),
      success: function (res) {
        if (res.success) {
          $('.mystery_shopper_poll').addClass('hidden');
          $('.mystery_shopper_thanks').removeClass('hidden');
          $form[0].reset();
        } else {
          alert('Ошибка');
        }
      }
    });
  })
  $('.mystery_shopper_thanks button').on('click', function() {
    $('.mystery_shopper_thanks').addClass('hidden');
    $('.mystery_shopper_enter').removeClass('hidden');
  });
});