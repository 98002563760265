function ya_commerce_cart(product_info, action_type, quantity, uuid) {
  quantity = quantity || 1;
  action_type = action_type || "add";
  product_info.quantity = quantity;

  var data_layer_elem = {
    "ecommerce": {
    }
  };

  data_layer_elem.ecommerce[action_type] = {};
  data_layer_elem.ecommerce[action_type].products = [product_info];

  dataLayer.push(data_layer_elem);

  if (uuid) {
    var local_storage_item = JSON.parse(localStorage.getItem("cart_items_uuid") || "{}");
    delete local_storage_item[uuid];
    localStorage.setItem("cart_items_uuid", JSON.stringify(local_storage_item));
  }
}


$(document).ready(function() {

  var need_push = false;
  var ya_commerce = $('.yandex_commerce');
  var data_layer_elem = {
    "ecommerce": {
    }
  };

  if (ya_commerce.length) {
    var action_info = ya_commerce.filter('.action_info').first();
    var product_info_items = ya_commerce.filter('.product_info_item');

    if (action_info.length) {
      data_layer_elem.ecommerce.purchase = {};
      data_layer_elem.ecommerce.purchase.actionField = action_info.data('info');
      if (typeof(localStorage) !== "undefined") {
        localStorage.removeItem("cart_items_uuid");
      }
    }

    if (product_info_items.length) {
      product_info_items.each(function() {
        var $this = $(this);
        var action_type = $this.data('action-type');

        if ($this.data('local-storage-uuid') && typeof(localStorage) !== "undefined") {
          var local_storage_item = JSON.parse(localStorage.getItem("cart_items_uuid") || "{}");
          var item_uuid = $this.data('local-storage-uuid');
          if (local_storage_item && local_storage_item[item_uuid]) {
            return;
          }
          local_storage_item[item_uuid] = true;
          localStorage.setItem("cart_items_uuid", JSON.stringify(local_storage_item));
        }

        if (!data_layer_elem.ecommerce[action_type]) {
          data_layer_elem.ecommerce[action_type] = {};
        }
        if (!data_layer_elem.ecommerce[action_type].products) {
          data_layer_elem.ecommerce[action_type].products = [];
        }

        data_layer_elem.ecommerce[action_type].products.push($this.data('product-info'));
        need_push = true;
      });

      if (need_push) {
        dataLayer.push(data_layer_elem);
      }
    }
  }

});