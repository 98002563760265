// $(document).ready(function() {
//   function page_detail_header() {
//     if (window.innerWidth < 1024) {
//       $('#pjax_container_detail .info_header').prependTo('#pjax_container_detail');
//       $('.main_information_container .info_header').prependTo('.main_information_container');
//     } else {
//       $('#pjax_container_detail .info_header').prependTo('#pjax_container_detail .product_detail_info');
//       $('.main_information_container .info_header').prependTo('.main_information_container .product_detail_info');
//     }
//   }
//   if ($('.product_detail_container, .page_promo').length) {
//     page_detail_header();
//     $(window).resize(function() {
//       page_detail_header();
//     });
//
//     $(document).on("change", ".product_detail_params", function() {
//       var $this = $(this);
//       var pjax_link = $this.closest('form').find('.pjax_update');
//       if ($this.hasClass('check_params')) {
//         pjax_link.attr('href', $this.attr('data-url'));
//       } else {
//         pjax_link.attr('href', $this.find('option:selected').attr('data-url'));
//       }
//       pjax_link.click();
//     });
//
//     $(document).on('click', '.product_detail_gallery .show_youtube_video', function () {
//       send_metrika_goal('PLAYVIDEO_DETAIL');
//     });
//
//     $(document).on('click', '.pjax_detail.slick-active', function () {
//       var current_slide = $(this).closest('.slider_product').slick('slickCurrentSlide');
//       $('body').attr('data-current-slide', current_slide + 1);
//     });
//
//     $(document).on('click', '.rate_star.j-rate_star', function () {
//       $('#tab_3').prop("checked", true).trigger('change');
//     });
//
//     $(document).on('click', '.view_detail_tab', function () {
//       $('#tab_1').prop("checked", true).trigger('change');
//     });
//
//     $(document).on("change", '.add_photo_input', function () {
//       var $this = $(this);
//       var $preview_image = $this.parent().find('.preview_img');
//       var $icon = $this.parent().find('.icon');
//       var input = $this[0];
//       if (input.files && input.files[0]) {
//         if (input.files[0].type.match('image.*')) {
//           var reader = new FileReader();
//           reader.onload = function (e) {
//             $preview_image.attr('src', e.target.result);
//             $preview_image.removeClass('hidden');
//             $icon.addClass('hidden');
//           };
//           reader.readAsDataURL(input.files[0]);
//         } else {
//           $preview_image.addClass('hidden');
//           $icon.removeClass('hidden');
//         }
//       } else {
//         $preview_image.addClass('hidden');
//         $icon.removeClass('hidden');
//       }
//     });
//
//     $(document).on('input', '.comments_form textarea', function() {
//       if (($('.rate_star input').filter('[checked]').length === 0) || ($('.comments_form textarea').val() === "")) {
//         $(this).closest('form').find('.submit_input').removeClass('active');
//       } else {
//         $(this).closest('form').find('.submit_input').addClass('active');
//       }
//     });
//
//     $(document).on('change', '.rate_star input', function() {
//       if (($('.rate_star input').filter('[checked]').length === 0) || ($('.comments_form textarea').val() === "")) {
//         $(this).closest('form').find('.submit_input').removeClass('active');
//       } else {
//         $(this).closest('form').find('.submit_input').addClass('active');
//       }
//     });
//
//     $(document).on('click', '.comments_form .submit_input', function (e) {
//
//       if ($('.rate_star input').filter('[checked]').length === 0) {
//         $('.error_rate').removeClass('hidden');
//       } else {
//         $('.error_rate').addClass('hidden');
//         $('.rate_star').addClass('valid');
//       }
//
//       if ($('.comments_form textarea').val() === "") {
//         $('.error_text').removeClass('hidden');
//       } else {
//         $('.comments_form textarea').addClass('valid');
//         $('.error_text').addClass('hidden');
//       }
//
//       if ($('.comments_form .valid').length !== 2) {
//         e.preventDefault();
//       }
//     });
//
//     $(document).on('click', '.benefits_link_block', function () {
//       information_popup_tab($(this));
//     });
//
//     $(document).on('click', 'a.jscroll-next-click', function() {
//       $(this).addClass('active');
//       $('a.jscroll-next:last').click();
//       return false;
//     });
//
//     $(document).on('click', '.gallery_image_preview', function() {
//       var fullview_img = $('.gallery_image_fullview');
//       var $this = $(this);
//       fullview_img.attr('src', $this.data('fullview-src'));
//       fullview_img.attr('data-src', $this.data('src'));
//       fullview_img.attr('alt', $this.data('alt'));
//       fullview_img.attr('title', $this.data('title'));
// //      if (window.innerWidth > 1025) {
// //        $('.product_fullview .img_wrap .img-parent .zoomImg').remove();
// //        $('.product_fullview .img_wrap .img-parent').zoom({url: fullview_img.attr('data-src')});
// //      }
//     });
//
//     $(document).on('submit', '.detail_cart_action form', function() {
//       send_metrika_goal('CLICK_FORM_BUTTON_GOTO_EDITOR')
//     });
//
//     $(document).on('click', '.product_fullview img', function() {
//       var $create_form = $('.page_promo_tabs_container form, .detail_cart_action form');
//       var $product_detail_info = $('.product_detail_info');
//       if ($product_detail_info.hasClass('not_available')) {
//         $('.detail_cart_action a.open_popup').click();
//       } else {
//         if ($create_form.length) {
//           send_metrika_goal('CLICK_PRODUCT_IMAGE_GOTO_EDITOR');
//           $create_form.first().submit();
//         }
//       }
//     });
//
//     $(document).on('click', '.product_detail_info .wholesale_price button', function() {
//       var $tab_label = $('.product_detail_tabs .tab_label.tbl4');
//       $tab_label.click();
//       var for_beauty = 15;
//       var html_scroll = $tab_label.offset().top - for_beauty;
//       if ($(window).width() <= 1023) {
//         html_scroll -= $('header .top_header_container').outerHeight();
//         html_scroll -= $('header .banner_container').outerHeight();
//       }
//       $('html').animate({scrollTop: html_scroll}, 500, 'swing');
//     });
//
//     $(document).on('click', '.go_to_editor', function(e) {
//       var $button = $(this);
//       $button.prop('disabled', true);
//       $.ajax({
//         url: $button.data('get-product-json'),
//         type: 'GET',
//         success: function (json) {
//           $.ajax({
//             url: $button.data('post-editor-link'),
//             type: 'POST',
//             dataType: 'json',
//             contentType: 'application/json',
//             data: JSON.stringify(json),
//             success: function (editor) {
//               $.ajax({
//                 url: $button.data('post-editor-save'),
//                 type: 'POST',
//                 dataType: 'json',
//                 contentType: 'application/json',
//                 data: JSON.stringify(editor)
//               });
//               window.location.href = $button.data('redirect-editor') + editor.user_editor_url;
//             }
//           });
//         }
//       });
//     });
//
//     page_product_list.jscroll();
//   }
// });
