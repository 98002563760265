$(document).ready(function () {
  $('header .header_top_menu .consultant').click(function() {
    Chatra('openChat', true);
  });
  show_timeout = null;
  $('header .nav_menu_item, header .choice, .header_content .cart').hover(
    function() {
      var $this = $(this);
      $(this).addClass('hovered');
      show_timeout = setTimeout(function() {
        $this.find('.sub_menu_container').addClass('active');
        $this.find('.cart_popup').css('display', 'block');
      }, 150);
    },
    function() {
      var $this = $(this);
      $(this).removeClass('hovered');
      clearTimeout(show_timeout);
      setTimeout(function() {
        if (!$this.find('.sub_menu_container').hasClass('hovered')) {
          $this.find('.sub_menu_container').removeClass('active');
        }
        if (!$this.find('.cart_popup').hasClass('hovered')) {
          $this.find('.cart_popup').css('display', 'none');
        }
      }, 150);
    }
  );
  $('header .sub_menu_container, header .cart_popup').hover(
    function() {
      $(this).addClass('hovered');
    },
    function() {
      $(this).removeClass('hovered');
    }
  );
});