function initialize_sliders() {
  // СЛАЙДЕР НА СТРАНИЦЕ КАРТОЧКИ ТОВАРА И ПРОМО-СТРАНИЦАХ
//  if (window.innerWidth > 1025) {
//    $('.product_fullview').waitForImages().done(function() {
//      $('.product_fullview .img_wrap .img-parent').zoom();
//    });
//  }

  var active_index = $('body').attr('data-current-slide') || $('.slider_product a.active').index();
  $('body').attr('data-current-slide', '');
  if (active_index && $('.slider_product a').length < 4) {
    active_index = 0;
  }

  if ($('.product_fullview').length) {
    $('.slider_product').slick({
      infinite: false,
      slidesToShow: 4,
      slidesToScroll: 1,
      dots: false,
      arrows: true,
      initialSlide: active_index ? active_index - 1 : 0,
      responsive: [
        {
          breakpoint: 1279,
          settings: {slidesToShow: 3}
        }
      ]
    })
  }
}

$(document).ready(function() {
  if ($('.product_fullview').length) {
    initialize_sliders();
  }
  //  ОСНОВНОЙ СЛАЙДЕР
  var $slider = $('section.slider');
  $slider.find('img').ready(function () {
    $slider.find('a').removeClass('loading');
    $slider.not('.slick-initialized').slick({
      dots: true,
      fade: true,
      cssEase: 'linear',
      autoplay: true,
      autoplaySpeed: 5000,
      infinite: true,
      speed: 1000,
      arrows: false
    });
  });

  if ($('.create_gift_block').length) {
    //  СЛАЙЕДЕР В КАРТОЧКЕ ТОВАРА(НИЖНЯЯ КАРУСЕЛЬ)
    $('.create_gift_block').not('.slick-initialized').slick({
      autoplay: true,
      autoplaySpeed: 2000,
      infinite: true,
      slidesToShow: 4,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1280,
          settings: {slidesToShow: 3}
        },
        {
          breakpoint: 830,
          settings: {slidesToShow: 2}
        },
        {
          breakpoint: 525,
          settings: {slidesToShow: 1}
        }
      ]
    });
  }

  //  СЛАЙДЕР ВВЕРХУ ДЛЯ СТРАНИЦ КНИГИ, КАЛЕНДАРИ И ХОЛСТЫ
  if ($('.page_promo .header_slider').length) {
    $('.page_promo .header_slider').not('.slick-initialized').slick({
      dots: false,
      autoplay: false,
      arrows: false,
      slidesToShow: 3,
      responsive: [
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
            dots: true,
            autoplay: true,
            autoplaySpeed: 3000,
            cssEase: 'linear',
            infinite: true,
            speed: 1000,
            fade: true
          }
        }
      ]
    });
  }
  if ($('.reasons_slide_block').length) {
    $('.reasons_slide_block').not('.slick-initialized').slick({
      dots: false,
      autoplay: false,
      arrows: false,
      slidesToShow: 5,
      responsive: [
        {
          breakpoint: 1279,
          settings: {
            slidesToShow: 3,
            autoplay: true,
            autoplaySpeed: 3000,
            cssEase: 'linear',
            infinite: true,
            speed: 1000,
            arrows: true
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            autoplay: true,
            autoplaySpeed: 3000,
            cssEase: 'linear',
            infinite: true,
            speed: 1000,
            arrows: true
          }
        }
      ]
    });
  }

  // СЛАЙДЕР НА СТРАНИЦЕ ПРОМО-ПОДАРКОВ
  if ($('.motive_slider').length) {
    $('.motive_slider').slick({
      slidesToShow: 7,
      slidesToScroll: 1,
      arrows: true,
      dots: false,
      responsive: [
        {
          breakpoint: 767,
          settings: { slidesToShow: 4 }
        },
        {
          breakpoint: 479,
          settings: { slidesToShow: 3 }
        }
      ]
    })
  }

  if ($('.promoblocks_container').length) {
    // Слайдер промоблоков для < 767px
    function promoblock_slider() {
      if ($('.promoblocks_content').length) {
        $('.promoblocks_content').slick({
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
          dots: false,
          fade: false,
          mobileFirst: true,
          responsive: [{
            breakpoint: 767,
            settings: 'unslick'
          }]
        });
      }
    }
    promoblock_slider();
    $(window).resize(function () {
      setTimeout(function () {
        promoblock_slider();
      }, 100);
    });

    // Переключение контейнеров при width > 767px
    $(document).on('click', '.nav_promo .nav_item', function (e) {
      e.preventDefault();

      var $anchor = $(this).attr('data-anchor');

      $('.nav_promo .nav_item').removeClass('active');
      $(this).addClass('active');

      $('.promoblocks_container .promoblocks_block').removeClass('active');
      $('.promoblocks_container .promoblocks_block[data-anchor="' + $anchor + '"]').addClass('active');
    })
  }
});
