$(document).ready(function() {
  if (!$('.page.product').length) return;
  function syncProductPhotoViewers() {
    var visible_item_index = $('.img-viewer.photo .item.visible').data('index');
    $('.img-viewer.preview .item').removeClass('active');
    var $active_item = $('.img-viewer.preview .item[data-index='+ visible_item_index +']');
    $active_item.addClass('active');
    if (!$active_item.hasClass('visible')) {
      var $next_item = $active_item.next();
      if ($next_item.hasClass('item') && $next_item.hasClass('visible')) {
        $active_item.closest('.img-viewer').find('.button.prev').click();
        return;
      }
      var $prev_item = $active_item.prev();
      if ($prev_item.hasClass('item') && $prev_item.hasClass('visible')) {
        $active_item.closest('.img-viewer').find('.button.next').click();
      }
    }
  }
  var $items = $('.options_product .type .img-viewer .item');
  var viewer_offset = location.hash ? parseInt(location.hash.slice(1)) : $items.index($items.filter('.active'));
  $('.options_product .type .img-viewer').attr('data-offset', viewer_offset);
  $('.img-viewer').trigger('init');
  $('.img-viewer.photo').on('change', syncProductPhotoViewers);
  syncProductPhotoViewers();
});
$(document).on('click', '.page.product .img-viewer.preview .item', function() {
  var photo_index = $(this).data('index');
  $('.img-viewer.photo .item[data-index='+ photo_index +']').trigger('visualize');
});
$(document).on('click', '.page.product .options_product .type .table a.not_available', function (e) {
  e.preventDefault();
});
$(document).on('change', '.page.product .options_product .type .select select', function(e) {
  window.location.href = $(this).find('option:selected').data('action');
});
$(document).on('click', '.page.product .info_product .rate_star .counter', function(e) {
  var $reviews_product = $('.product .reviews_product');
  var $header = $('header');
  var beauty = 5;
  var offset = $reviews_product.offset().top - beauty;
  if ($header.css('position') === 'fixed') {
    offset -= $header.outerHeight();
  }
  $('html').animate({scrollTop: offset}, 300);
});
$(document).on('click', '.page.product .options_product .wholesale_price button', function(e) {
  var $wholesale_prices = $('.product .wholesale_prices');
  var $header = $('header');
  var beauty = 5;
  var offset = $wholesale_prices.offset().top - beauty;
  if ($header.css('position') === 'fixed') {
    offset -= $header.outerHeight();
  }
  $('html').animate({scrollTop: offset}, 300);
});
$(document).on('click', '.page.product .go_to_editor, .page.catalog .go_to_editor', function(e) {
  e.preventDefault();
  var $target = $(e.target);
  if ($target.hasClass('color') || $target.parent().hasClass('color')) return;
  if ($target.hasClass('swipe') || $target.parent().hasClass('swipe')) return;
  var $button = $(this);
  $button.prop('disabled', true);
  $.ajax({
    url: $button.data('get-product-json'),
    type: 'GET',
    success: function (json) {
      $.ajax({
        url: $button.data('post-editor-link'),
        type: 'POST',
        dataType: 'json',
        contentType: 'application/json',
        data: JSON.stringify(json),
        success: function (editor) {
          $.ajax({
            url: $button.data('post-editor-save'),
            type: 'POST',
            dataType: 'json',
            contentType: 'application/json',
            data: JSON.stringify(editor)
          });
          window.location.href = $button.data('redirect-editor') + editor.user_editor_url;
        }
      });
    }
  });
});
$(document).on('click', '.page.product .add_to_cart', function(e) {
  e.preventDefault();
  show_loader();
  var $button = $(this).prop('disabled', true);
  $.ajax({
    url: $button.data('url'),
    type: 'POST',
    data: $button.data('data'),
    beforeSend: function(xhr) {
      xhr.setRequestHeader("X-CSRFToken", getCookie("csrftoken"));
    },
    success: function() {
      location.reload();
    }
  })
});
$(document).on('click', '.page.product .options_product .type .img-viewer a', function(e) {
  var $viewer = $(this).closest('.img-viewer');
  var viewer_offset = $viewer.find('.item').index($viewer.find('.item.visible:first'));
  $(this).attr('href', $(this).attr('href') + '#' + viewer_offset);
});
$(document).on('change', '.page.product .order_with_designer .file-set .file input', function(e) {
  var $this = $(this);
  var reader = new FileReader();
  var file = this.files[0];
  if (file.type.match('image.*')) {
    reader.onload = function (e) {
      var $file = $this.closest('.file');
      var $clone = $file.clone();
      $file.addClass('loaded');
      $this.prop('disabled', true);
      $this.after($('<img class="content" src="'+ e.target.result +'"/>'));
      $clone.find('input').val('');
      $file.after($clone);
    }
  } else {
    reader.onload = function (e) {
      var $file = $this.closest('.file');
      var $clone = $file.clone();
      $file.addClass('loaded');
      $this.prop('disabled', true);
      $this.after($('<span class="content">'+ file.name +'</span>'));
      $clone.find('input').val('');
      $file.after($clone);
    }
  }
  reader.onerror = function (e) {
    alert('Ошибка загрузки файла');
  }
  reader.readAsDataURL(this.files[0]);
});
$(document).on('click', '.page.product .order_with_designer .file-set .file .close', function() {
  $(this).closest('.file').remove();
});
