var page_product_list = {
  init_catalog_cells: function($cells) {
    var ajax_pending = false;
    var addGalleryButtonsEvents = function($gallery_wrap) {
      if ($gallery_wrap.find('.catalog_cell_img').length < 2) return;
      $gallery_wrap.find('.gallery_button').removeClass('hidden');
      $gallery_wrap.find('.gallery_button.prev').on('click', function() {
        if (ajax_pending) return false;
        var $current_img = $gallery_wrap.find('.gallery_container .catalog_cell_img.active');
        var $prev_img = $current_img.prev();
        if (!$prev_img.hasClass('catalog_cell_img')) {
          $prev_img = $gallery_wrap.find('.catalog_cell_img:last-child');
        }
        $current_img.removeClass('active');
        $prev_img.addClass('active');
      });
      $gallery_wrap.find('.gallery_button.next').on('click', function() {
        if (ajax_pending) return false;
        var $current_img = $gallery_wrap.find('.gallery_container .catalog_cell_img.active');
        var $next_img = $current_img.next();
        if (!$next_img.hasClass('catalog_cell_img')) {
          $next_img = $gallery_wrap.find('.catalog_cell_img:first-child');
        }
        $current_img.removeClass('active');
        $next_img.addClass('active');
      });
    };
    var addColorButtonsEvents = function($product_colors, $gallery_wrap) {
      $product_colors.find('.color').on('click', function() {
        var $button = $(this);
        if (ajax_pending || $(this).hasClass('active')) return false;
        ajax_pending = true;
        $.ajax({
          url: $(this).data('url'),
          type: 'GET',
          success: function(data) {
            var $gallery_container = $gallery_wrap.find('.gallery_container');
            var $images = $gallery_container.find('.catalog_cell_img');
            var $active_image = $gallery_container.find('.catalog_cell_img.active');
            if (data.product_detail_url) {
              $gallery_container.attr('href', data.product_detail_url);
              $product_colors.find('.color').removeClass('active');
              $button.addClass('active');
            }
            if (data.gallery_images && data.gallery_images.length) {
              for (var j = 0; j < data.gallery_images.length; j++) {
                if (data.gallery_images[j].slug === 'context') {
                  data.gallery_images.splice(j, 1);
                }
              }
            }
            if (data.gallery_images && data.gallery_images.length) {
              var active_image_index = $active_image.length ? $images.index($active_image) : 0;
              active_image_index = active_image_index > (data.gallery_images.length - 1) ? 0 : active_image_index;
              for (var i = 0; i < data.gallery_images.length; i++) {
                var item = data.gallery_images[i];
                var image_active = active_image_index === i ? ' active' : '';
                var image_data = 'class="catalog_cell_img lazy hidden' + image_active + '" ';
                image_data += 'src="' + item.image + '" ';
                image_data += 'title="' + item.image_title + '" ';
                image_data += 'alt="' + item.image_alt + '"';
                var $image = $('<img ' + image_data + '/>');
                $gallery_container.append($image);
              }
              var $new_active_image = $gallery_container.find('.catalog_cell_img.active.hidden');
              $new_active_image.on('load', function() {
                var $new_images = $gallery_container.find('.catalog_cell_img.hidden');
                var $old_images = $gallery_container.find('.catalog_cell_img:not(.hidden)');
                $new_active_image.removeClass('hidden');
                $new_images.removeClass('hidden');
                setTimeout(function () {
                  $old_images.remove();
                  ajax_pending = false;
                }, 100);
              });
            } else {
              ajax_pending = false;
            }
          },
          error: function() {
            ajax_pending = false;
          }
        });
      });
    };
    var addImageEvents = function($gallery_wrap) {
      $gallery_wrap.find('img').on('error', function () {
        $(this).attr('src', '/static/img/404/nopicture.png');
      });
    };
    $catalog_cells = $cells || $('.catalog_block .catalog_cell');
    $catalog_cells.each(function() {
      var $gallery_wrap = $(this).find('.gallery_wrap');
      addGalleryButtonsEvents($gallery_wrap);
      addImageEvents($gallery_wrap);
      var $product_colors = $(this).find('.product_colors');
      addColorButtonsEvents($product_colors, $gallery_wrap);
    });
  },
  jscroll: function(auto_trigger, page_selector, content_selector) {
    content_selector = content_selector || '';
    if (!auto_trigger) {
      auto_trigger = !!$('.catalog_block').length;
    }
    if ($('a.jscroll-next').length) {
      $('.jscroll').jscroll({
        nextSelector: 'a.jscroll-next:last',
        loadingHtml: '<div class="loader"></div>',
        autoTrigger: auto_trigger,
        contentSelector: content_selector,
        callback: function () {
          page_product_list.init_catalog_cells($('.jscroll-added .catalog_cell'));
          $(".jscroll-added:last img.lazy").lazyload({placeholder: 'data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEASABIAAD//gATQ3JlYXRlZCB3aXRoIEdJTVD/2wBDAAMCAgMCAgMDAwMEAwMEBQgFBQQEBQoHBwYIDAoMDAsKCwsNDhIQDQ4RDgsLEBYQERMUFRUVDA8XGBYUGBIUFRT/2wBDAQMEBAUEBQkFBQkUDQsNFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBT/wgARCAABAAEDAREAAhEBAxEB/8QAFAABAAAAAAAAAAAAAAAAAAAACP/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAUYf/8QAFBABAAAAAAAAAAAAAAAAAAAAAP/aAAgBAQABBQJ//8QAFBEBAAAAAAAAAAAAAAAAAAAAAP/aAAgBAwEBPwF//8QAFBEBAAAAAAAAAAAAAAAAAAAAAP/aAAgBAgEBPwF//8QAFBABAAAAAAAAAAAAAAAAAAAAAP/aAAgBAQAGPwJ//8QAFBABAAAAAAAAAAAAAAAAAAAAAP/aAAgBAQABPyF//9oADAMBAAIAAwAAABAf/8QAFBEBAAAAAAAAAAAAAAAAAAAAAP/aAAgBAwEBPxB//8QAFBEBAAAAAAAAAAAAAAAAAAAAAP/aAAgBAgEBPxB//8QAFBABAAAAAAAAAAAAAAAAAAAAAP/aAAgBAQABPxB//9k='});
          if (!page_selector) {
            if ($('main.catalog').length) {
              if ($('.view_more_block').length > 1) {
                $('.view_more_block').not(':last').remove();
              } else {
                $('.view_more_block').remove();
              }
              if ($('.paginations_block').length > 1) {
                $('.paginations_block').not(':last').remove();
              } else {
                $('.paginations_block').remove();
              }
            }
          } else {
            if ($(page_selector).length > 1) {
              $(page_selector).not(':last').remove();
            } else {
              $(page_selector).remove();
            }
          }
        }
      });
    } else {
      if ($('.jscroll').jscroll.destroy) {
        $('.jscroll').jscroll.destroy()
      }
    }
  }
};

$(document).ready(function() {
  if ($('.catalog_page_container').length) {
    page_product_list.jscroll();
    $(document).on('click', 'a.jscroll-next-click', function() {
      $(this).addClass('active');
      $('a.jscroll-next:last').click();
      return false;
    });

    $(document).on('click', 'a.page_navigation_link', function(e) {
      e.preventDefault;
      var $this = $(this);
      var $loaded_page_id = '#catalog_block_page_' + $this.text() + '_from';
      $this.removeClass('page_navigation_link');
      if ($($loaded_page_id).length) {
        $this.attr('href', $loaded_page_id);
      } else {
        $this.addClass('pjax_update');
      }
      $this.click();
    });

    $(document).on('click', '.popup_detail .catalog_cell_button, .popup_detail .gallery_wrap>a', function(e) {
      if (window.innerWidth > 1023) {
        $('#books_popup').addClass('active_popup');
        $('.popup_container.books_popup .pjax_detail').attr('href', $(this).attr('href'));
        $('.popup_container.books_popup .pjax_detail').click();
        return false;
      }
    });

    page_product_list.init_catalog_cells();
  }
});
