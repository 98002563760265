$(window).on('load', function() {
  var $comparison_table = $('.comparison_table');
  var $slide_button_left = $comparison_table.find('.slide_button.left');
  var $slide_button_right = $comparison_table.find('.slide_button.right');
  var $slide_table = $comparison_table.find('.slide_table');
  var $popup_show_buttons = $comparison_table.find('.row_hint_set .row_hint .popup_show_button');
  var $popup_close_button = $comparison_table.find('.row_hint_set .row_hint .popup .close');
  var $popups = $comparison_table.find('.row_hint_set .row_hint .popup');
  var offset = 0;
  var hidden_columns_counter = 0;

  updateButtons = function() {
    if ($slide_table.outerWidth() - $comparison_table.innerWidth() - offset > 0) {
      $slide_button_right.removeClass('invisible');
    } else {
      $slide_button_right.addClass('invisible');
    }
    if (offset > 0) {
      $slide_button_left.removeClass('invisible');
    } else {
      $slide_button_left.addClass('invisible');
    }
  };
  updateRowHints = function() {
    var row_heights = [];
    $slide_table.find('tr').each(function() {
      row_heights.push($(this).outerHeight());
    });
    var hint_offsets = [];
    row_heights.forEach(function(item, index) {
      if (index > 1) {
        var offset = 0;
        for (var i = 0; i < index; i++) {
          offset += row_heights[i];
        }
        hint_offsets.push(offset);
      }
    });
    $comparison_table.find('.row_hint_set .row_hint').each(function(index) {
      if (hint_offsets[index]) {
        $(this).css('top', hint_offsets[index]).removeClass('invisible');
      }
    });
  };

  $slide_button_right.click(function() {
    overleft_offset = $slide_table.outerWidth() - $comparison_table.innerWidth() - offset;
    if (overleft_offset > 0) {
      hidden_columns_counter++;
      var $column = $slide_table.find('th:nth-of-type('+ hidden_columns_counter +')');
      if ($column.length) {
        offset += $column.outerWidth();
        $slide_table.css('left', -offset);
        updateButtons();
      }
    }
  });
  $slide_button_left.click(function() {
    if (hidden_columns_counter > 0) {
      var $column = $slide_table.find('th:nth-of-type('+ hidden_columns_counter +')');
      if ($column.length) {
        offset -= $column.outerWidth();
        hidden_columns_counter--;
        $slide_table.css('left', -offset);
        updateButtons();
      }
    }
  });
  $popup_show_buttons.click(function() {
    if (!$(this).hasClass('active')) {
      $popup_show_buttons.removeClass('active');
      $popups.addClass('invisible');
    }
    $(this).toggleClass('active');
    $(this).parent().find('.popup').toggleClass('invisible');
  });
  $popup_close_button.click(function () {
    $(this).parent().addClass('invisible');
    $(this).parent().parent().find('.popup_show_button').removeClass('active');
  });
  $(window).on('resize', function() {
    updateButtons();
    updateRowHints();
  });

  updateButtons();
  updateRowHints();
});