$(document).ready(function() {
  $(document).on('click', '#sidebar .consultant', function(e) {
    Chatra('openChat', true);
  });
  // Открытие попапов
  $(document).on('click', '.open_popup', function() {
    if ($(this).is('#user_return_open')) {
      send_metrika_goal('LAST_EDITOR_OPENING');
    }
    var $href = $(this).attr('href');
    $('html').css('overflow', 'hidden');
    $('.popup_overlay').removeClass('active_popup');
    $($href).addClass('active_popup');
  });
  $(document).on('click', '.open_popup.show_product_review', function() {
    var addSliderEvents = function($slider) {
      $slider.find('.slide_button.prev').on('click', function() {
        var $current_slide = $slider.find('.slide:not(.hidden)');
        var $prev_element = $current_slide.prev();
        if ($prev_element.hasClass('slide')) {
          $current_slide.addClass('hidden');
          $prev_element.removeClass('hidden');
          refreshSlideButtons($slider);
        }
      });
      $slider.find('.slide_button.next').on('click', function() {
        var $current_slide = $slider.find('.slide:not(.hidden)');
        var $next_element = $current_slide.next();
        if ($next_element.hasClass('slide')) {
          $current_slide.addClass('hidden');
          $next_element.removeClass('hidden');
          refreshSlideButtons($slider);
        }
      });
    };
    var refreshSlideButtons = function($slider) {
      $slider.find('.slide_button').addClass('invisible');
      var $current_slide = $slider.find('.slide:not(.hidden)');
      if ($current_slide.prev().hasClass('slide')) {
        $slider.find('.slide_button.prev').removeClass('invisible');
      }
      if ($current_slide.next().hasClass('slide')) {
        $slider.find('.slide_button.next').removeClass('invisible');
      }
    };

    var $this = $(this);
    $.get({
      url: $this.data('url'),
      success: function (data) {
        var $content_container = $($this.attr('href')).next().find('.content_container');
        var activate_slide_index = $this.data('slide') || 1;
        $content_container.html(data);
        $content_container.find('iframe').css('width', '100%').css('height', '100%');
        $content_container.find('.slide[data-slide=' + activate_slide_index + ']').removeClass('hidden');
        $slider = $content_container.find('.slider');
        refreshSlideButtons($slider);
        addSliderEvents($slider);
      }
    });
  });
  $(document).on('click', '.open_popup.video_cell', function() {
    var $this = $(this);
    $.get({
      url: $this.data('url'),
      success: function (data) {
        $($this.attr('href')).next().find('.content_container').html(data)
      }
    })
  });
  $(document).on('click', '.open_popup.show_big_photo', function() {
    $('#big_photo_in_popup').attr('src', $(this).data('original-src'));
  });
  $(document).on('click', '.open_popup.show_youtube_video', function() {
    $('#youtube_in_popup').before('<iframe class="youtube_frame" width="560" height="315" src="' +
      $(this).data('youtube-video') + '?rel=0&autoplay=1" frameborder="0" allowfullscreen></iframe>');
  });

  // Закрытие попапов, при нажатии на оверлей
  $(document).on('click', '.popup_overlay' , function() {
    if ($(this).is('#user_return')) {
      send_metrika_goal('LAST_EDITOR_CLOSURE');
    }
    $(this).removeClass('active_popup');
    $('html').css('overflow', 'visible');
    if ($(this).attr('href') === '#video_review') {
      $(this).next().find('.video_container').remove();
    }
    else if ($(this).attr('href') === '#photo_review') {
      $(this).next().find('.content_container').empty();
    }
    if ($('#big_photo_in_popup').length) {
      $('#big_photo_in_popup').attr('src', '');
    }
    if ($('#youtube_in_popup').length) {
      $('#youtube_in_popup').prev().remove();
    }
  });
  $(document).on('click', '.close_popup, .close_detail_popup,.close_popup_button', function(e) {
    e.preventDefault();
    if ($(this).is('#user_return')) {
      send_metrika_goal('LAST_EDITOR_CLOSURE');
    }
    $(this).closest('.popup_container').prev().removeClass('active_popup');
    $('html').css('overflow', 'visible');

    if ($(this).closest('.popup_container').hasClass('video_review')) {
      $(this).closest('.popup_container').find('.video_container').remove();
    }
    else if ($(this).closest('.popup_container').hasClass('photo_review')) {
      $(this).closest('.popup_container').find('.content_container').empty();
    }
    else if ($(this).closest('.popup_container').hasClass('product_review')) {
      $(this).closest('.popup_container').find('.content_container').empty();
    }
    if ($('#big_photo_in_popup').length) {
      $('#big_photo_in_popup').attr('src', '');
    }
    if ($('#youtube_in_popup').length) {
      $('#youtube_in_popup').prev().remove();
    }
  });

  // Смена форм регистрации, авторизации и пароля
  $(document).on('click', '.authorization_page_main .open_popup', function(e) {
    e.preventDefault();
    var $attr = $(this).attr('href');

    $(this).closest('.authorization_page_main').find('.popup_container').removeClass('active');
    $($attr).addClass('active');
  });

  // Переключение табов в попапе Информации на странице товара
  $(document).on('click', '.information_detail .tab_cell', function () {
    information_popup_tab($(this))
  });

  // Клик по кнопек в попапае #user_return
  $(document).on('click', '.popup_container.user_return .to_finish', function() {
    send_metrika_goal('LAST_EDITOR_PUSH_ON_THE_BUTTON');
  });

  if ($('#user_return_open').length) {
    setTimeout(function () {
      $('#user_return_open').click();
    }, 3000);

  }
});
