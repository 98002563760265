function send_metrika_goal(goal_name) {
  try {
    if (typeof yaCounter32175144 !== "undefined") {
      yaCounter32175144.reachGoal(goal_name);
    }
  } catch (e) {}
}

function metrika_hit(url, options) {
  url = url || window.location.href;
  try {
    if (typeof yaCounter32175144 !== "undefined") {
      yaCounter32175144.hit(url, options);
    }
  } catch (e) {}
}