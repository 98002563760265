$(document).ready(function() {
  if (!$('.page_cart').length && !$('.page_delivery_container').length) return;
  var map;
  $(document).on('change', '.point_list .map .point .take input', function () {
    var n = $(this).closest('.point').data('n');
    $('.point_list .list .point[data-n='+ n +'] .take input').click();
    $('.point_list .view .show_list').click();
  });
  $('.point_list .list .point .take input').click(function() {
    if ($('.point_list .current_point').val() !== $(this).val()) {
      $.get('/cart/set_delivery_point' + '?' + $(this).attr('name') + '=' + $(this).val());
      $('.point_list .current_point').val($(this).val());
    }
    var $all_points = $('.point_list .list .point');
    $all_points.addClass('hidden');
    var hidden_points = $all_points.length - 1;
    var $current_point = $(this).closest('.point');
    $current_point.removeClass('hidden');
    if (hidden_points) {
      var $show_all = $('.point_list .list .show_all_points');
      $show_all.text($show_all.data('text') + '(' + hidden_points + ')');
      $show_all.removeClass('hidden');
    }
  });
  $('.point_list .list .show_all_points').click(function() {
    var $all_points = $('.point_list .list .point');
    $all_points.removeClass('hidden');
    var $show_all = $('.point_list .list .show_all_points');
    $show_all.addClass('hidden');
  });
  $('.point_list .point .show_map').click(function () {
    var $point = $(this).closest('.point');
    var $map = $point.find('.map');
    $point.toggleClass('map_shown');
    $point.removeClass('way_shown');
    if ($map.is(':empty')) {
      show_loader($map);
      ymaps.ready(function() {
        var map = new ymaps.Map($map[0], {
          center: [55.76, 37.64],
          zoom: 2
        });
        var icon_image_href = '/static/img/delivery_points_short/';
        if ($point.hasClass('boxberry')) {
          icon_image_href += 'boxberry.png';
        } else if ($point.hasClass('cdek')) {
          icon_image_href += 'cdek.png';
        } else {
          icon_image_href += 'papara.png';
        }
        var gps_coords = $point.find('.gps_coords').text().split(',');
        gps_coords[0] = parseFloat(gps_coords[0]);
        gps_coords[1] = parseFloat(gps_coords[1]);
        var point = new ymaps.Placemark(gps_coords);
        point.options.set({
          iconLayout: 'default#image',
          iconImageHref: icon_image_href,
          iconImageSize: [25, 32]
        });
        map.geoObjects.add(point);
        map.setCenter(point.geometry.getCoordinates());
        map.setZoom(16);
        hide_loader($map);
      });
    }
  });
  $('.point_list .point .show_way').click(function () {
    var $point = $(this).closest('.point');
    $point.removeClass('map_shown');
    $point.toggleClass('way_shown');
  });
  $('.point_list .view .show_map').click(function () {
    var $container = $(this).closest('.point_list');
    var $point_list = $container.find('.list');
    var $map = $container.find('.map .inner');
    $container.removeClass('list');
    $container.addClass('map');
    if ($map.is(':empty')) {
      show_loader($map);
      ymaps.ready(function () {
        map = new ymaps.Map($map[0], {
          center: [55.76, 37.64],
          zoom: 2
        });
        var clusterer = new ymaps.Clusterer({
          preset: 'islands#invertedDarkOrangeClusterIcons',
          hasBalloon: true,
          hasHint: false,
          zoomMargin: 15,
          clusterBalloonLeftColumnWidth: 60,
          clusterBalloonContentLayoutWidth: 340
        });
        map.geoObjects.add(clusterer);
        $point_list.find('.point').each(function () {
          var $point = $(this);
          var point_name = 'papara';
          if ($point.hasClass('boxberry')) point_name = 'boxberry';
          if ($point.hasClass('cdek')) point_name = 'cdek';
          var gps_coords = $point.find('.gps_coords').text().split(',');
          gps_coords[0] = parseFloat(gps_coords[0]);
          gps_coords[1] = parseFloat(gps_coords[1]);
          function balloonContent() {
             var $point_clone = $point.clone();
            $point_clone.find('.location_buttons, .map, .way').remove();
            $point_clone.removeClass('hidden');
            $point_clone.find('.take input').removeAttr('name');
            if ($point.find('.take input:checked').length) {
              $point_clone.find('.take input').attr('checked', 'checked');
            }
            var ballooon_content = '<div class="' + $point_clone.attr('class') + '" data-n="' + $point.attr('data-n') + '">';
            ballooon_content += $point_clone.html() + '</div>';
            return ballooon_content;
          }
          var point = new ymaps.Placemark(gps_coords, {
            balloonContentBody: balloonContent(),
            clusterCaption: point_name
          });
          point.options.set({
            iconLayout: 'default#image',
            iconImageHref: '/static/img/delivery_points_short/' + point_name + '.png',
            iconImageSize: [25, 32],
            openEmptyBalloon: true
          });
          clusterer.add(point);
        });
        map.setBounds(clusterer.getBounds(), {
          checkZoomRange: true
        });
        hide_loader($map);
      });
    }
  });
  $('.point_list .view .show_list').click(function () {
    var $container = $(this).closest('.point_list');
    if (map) map.balloon.close();
    $container.removeClass('map');
    $container.addClass('list');
  });

  var $current_point_data = $('.point_list .current_point');
  var $current_point = $('.point_list .list .point .take input[value="' + $current_point_data.val() + '"]');
  if ($current_point_data.val() && $current_point.length) {
    $current_point.click();
  } else {
    $('.point_list .list .point').removeClass('hidden');
  }
});