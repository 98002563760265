$(document).on('submit', '.page.order-check form', function(e) {
  var $form = $(this);
  e.preventDefault();
  $.ajax({
    type: 'POST',
    url: window.location.href,
    data: new FormData(this),
    processData: false,
    contentType: false,
    success: function(res) {
      if (res.success) {
        window.location.replace(res.url)
      } else {
        $form.addClass('error');
      }
    }
  })
});
$(document).on('keyup', '.page.order-check form input', function(e) {
  $(this).closest('form').removeClass('error');
});
