$(document).ready(function() {
  $page = $('.page.catalog main.content');

  if (!$page.length) return false;

  $(document).on('click','.go_to_editor_print', function() {
    var $button = $(this);
    $button.prop('disabled', true);
    $.ajax({
      type: 'GET',
      url: $button.data('get-product-json'),
      success: function(json) {
        $.ajax({
          type: 'POST',
          url: $button.data('post-editor-link'),
          dataType: 'json',
          contentType: 'application/json',
          data: JSON.stringify(json),
          success: function (editor) {
            $.ajax({
              url: $button.data('post-editor-save'),
              type: 'POST',
              dataType: 'json',
              contentType: 'application/json',
              data: JSON.stringify(editor)
            });
            window.location.href = $button.data('redirect-editor') + editor.user_editor_url;
          }
        })
      }
    })
  });
});
