$(document).ready(function() {
  create_ajax_form('#subscribe-form');
  create_ajax_form('.popup_container.autorization');
  create_ajax_form('.popup_container.registration');
  create_ajax_form('.popup_container.forgot_password');
  create_ajax_form('.reviews_form_container');
  create_ajax_form('.comments_form_container');
  create_ajax_form('.popup_container.change_adress');
	create_ajax_form('.popup_container.present');
  create_ajax_form('.popup_container.pre_order .popup_content');

  create_ajax_form('.total_promoblock', null, function(block_selector, data) {
    var error_list = $('.total_promoblock .errorlist');
    if (data.errors) {
      error_list.removeClass("hidden");
      error_list.html("<li>" + data.errors + "</li>")
    } else if (data.success_url) {
      error_list.addClass("hidden");
      show_loader();
      document.location.href = data.success_url
    }
  });

  // ПОПАП ПРОВЕРИТЬ СТАТУС
  $(document).on('click', '.top_header_container a.cell.status', function() {
    var $popup = $('#order_status').next();
    $.get($popup.attr('data-url'), function(data) {
      $popup.html(data).find('.phone-mask-input').inputmask("+7(999)9999999");
    });
  });

  if ($('.check_order form').length) {
    $(document).on('submit', '.check_order form', function(event) {
      event.preventDefault();
      var $this = $(this);
      var $popup = $this.closest('.popup_container');
      $.ajax({
        url: $this.attr('action'),
        method: 'POST',
        data: $this.serialize(),
        success: function (data) {
          $popup.html(data).find('.phone-mask-input').inputmask("+7(999)9999999");
        }
      })
    });
  }

  if ($('.page_profile_container').length) {
    $(document).on('click', 'a.change_adress', function() {
      var $this = $(this);
      var $address_id = $this.attr('data-address-id');
      if ($address_id) {
        $('.popup_container.change_adress .address_id').val($address_id);
        $('.popup_container input[name=postcode]').val($this.attr('data-postcode'));
        $('.popup_container input[name=city]').val($this.attr('data-city'));
        $('.popup_container input[name=street]').val($this.attr('data-street'));
        $('.popup_container input[name=house]').val($this.attr('data-house'));
        $('.popup_container input[name=korpus]').val($this.attr('data-korpus'));
        $('.popup_container input[name=apartment]').val($this.attr('data-apartment'));
      } else {
        $('.popup_container.change_adress .address_id').val('');
        $('.popup_container input[name=postcode]').val('');
        $('.popup_container input[name=city]').val('');
        $('.popup_container input[name=street]').val('');
        $('.popup_container input[name=house]').val('');
        $('.popup_container input[name=korpus]').val('');
        $('.popup_container input[name=apartment]').val('');
      }
    });
    $(document).on('click', '.delete_adress', function() {
      var url = $(this).attr('href') + '?address_id=' + $(this).attr('data-address-id');
      $.ajax({
        type: 'DELETE',
        url: url,
        beforeSend: function(xhr) {
          xhr.setRequestHeader("X-CSRFToken", getCookie("csrftoken"));
        },
        success: function(data) {
        },
        error: function (request, status, error) {
          alert("Произошла ошибка при удалении адреса.\nОбновите страницу и попробуйте снова.");
        }
      });
      $(this).closest('.adress_block').remove();
      return false;
    });
    $(document).on('change', '.delivery_input', function() {
      var data = {
        'address_id': $(this).attr('data-address-id'),
        'is_delivery': true
      };
      $.ajax({
        type: 'POST',
        url: $(this).attr('data-url'),
        data: data,
        beforeSend: function(xhr) {
          xhr.setRequestHeader("X-CSRFToken", getCookie("csrftoken"));
        },
        success: function(data) {
        },
        error: function (request, status, error) {
          alert("Произошла ошибка при изменении адреса.\nОбновите страницу и попробуйте снова.");
        }
      });
    });
  }
});
