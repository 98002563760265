$(document).ready(function () {
  var $desktop_promo_banner = $('.catalog_page_container .filtr_container .banner');
  var $mobile_promo_banner = $('.catalog_page_container .catalog_container .banner_promo');

  var applyPromoCode = function (href) {
    $.ajax({
      url: href
    }).done(function() {
      $desktop_promo_banner.addClass('applied');
      $mobile_promo_banner.addClass('applied');
    })
  };

  $desktop_promo_banner.click(function (e) {
    e.preventDefault();
    applyPromoCode($(this).attr('href'));
  });
  $mobile_promo_banner.click(function (e) {
    e.preventDefault();
    applyPromoCode($(this).attr('href'));
  });
});