$(document).ready(function () {
  if ($("input[type=tel]").length || $(".phone-mask-input").length) {
    $("input[type=tel]").inputmask("+7(999)9999999");
    $(".phone-mask-input").inputmask("+7(999)9999999");
    $(document).on("keydown change", ".phone-mask-input, input[type=tel]", function (e) {
      var $this = $(this);
      var val = $this.val();
      if ((val.indexOf("+7(89") === 0) || (val.indexOf("+7(88") === 0)) {
        var new_val = val.slice(0, 3) + val.slice(4, val.length);
        $this.val(new_val);
        $this.trigger("change", e);
      }
    });
  }
});