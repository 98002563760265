function productSliderSwipeUpdate($slider) {
  var $swipe = $slider.find('.swipe');
  $slider.find('.slide').length > 1 ? $swipe.removeClass('hidden') : $swipe.addClass('hidden');
}
function loadProductImages() {
  $('.page.catalog .product:not(.loaded)').each(function() {
    if ($(this).offset().top > $(window).outerHeight() + $(document).scrollTop()) return;
    var $first_slide = $(this).find('.slider .slide:first');
    var $current_slide = $(this).find('.slider .slide.current');
    if (!$current_slide.length) $current_slide = $first_slide;
    if (!$current_slide.length) return;
    var $slider = $(this).find('.slider');
    show_loader($slider);
    $current_slide.on('load', function() {
      $current_slide.removeClass('hidden');
      hide_loader($slider);
    });
    var $slides = $slider.find('.slide');
    $slides.each(function() {
      $(this).attr('src', $(this).data('src'));
    });
    productSliderSwipeUpdate($slider);
    $(this).addClass('loaded');
  });
}

$(document).ready(loadProductImages);
$(document).on('scroll', loadProductImages);
$(document).on('click','.page.catalog .content_filters .filter .toggle',function () {
  $(this).closest('.filter').toggleClass('shown');
});
$(document).on('click', '.page.catalog .content_filters .filter .apply', function() {
  var get_params = '?';
  $('.page.catalog .content_filters .filter').each(function() {
    var filter_enabled = false;
    var filter_params = $(this).data('param') + '=';
    $(this).find('input[type=checkbox]:checked').each(function() {
      filter_enabled = true;
      filter_params += $(this).val() + ',';
    });
    if (filter_enabled) {
      get_params += filter_params.slice(0, -1) + '&';
    }
  });
  get_params = get_params.slice(0, -1);
  location.replace(location.origin + location.pathname + get_params);
});
$(document).on('click', '.page.catalog .product .swipe', function(e) {
  e.preventDefault();
  var $swipe = $(this);
  var $slider = $swipe.closest('.slider');
  var $current_slide = $slider.find('.slide:not(.hidden)');
  var $next_slide = $current_slide;
  if ($swipe.hasClass('left')) {
    var $prev = $current_slide.prev();
    $next_slide = $prev.hasClass('slide') ? $prev : $slider.find('.slide:last');
  }
  if ($swipe.hasClass('right')) {
    var $next = $current_slide.next();
    $next_slide = $next.hasClass('slide') ? $next : $slider.find('.slide:first');
  }
  $slider.find('.slide').addClass('hidden');
  $next_slide.removeClass('hidden');
});
$(document).on('click', '.page.catalog .product .color', function (e) {
  e.preventDefault();
  var $color = $(this);
  var $product = $color.closest('.product');
  var $colors = $product.find('.colors .color').prop('disabled', true);
  var $slider = $product.find('.slider');
  var $slide_active = $slider.find('.slide:not(.hidden)');
  var slide_active_index = $slider.find('.slide').index($slide_active);
  var url = $color.data('api-url');
  $.ajax({
    url: url,
    type: 'GET',
    success: function(res) {
      $product.find('.description').text(res.product.parent_title);
      $product.attr('href', res.product.url);
      // $product.attr('data-get-product-json', $color.attr('data-api-url'));
      $product.find('.price .current').text(res.product.price + ' руб.');
      $colors.removeClass('active');
      $color.addClass('active');
      $slider.find('.slide').remove();
      if (res.product.images) {
        $product.removeClass('loaded');
        if (slide_active_index >= res.product.images.length) slide_active_index = 0;
        res.product.images.forEach(function(img, index) {
          if (index == slide_active_index) {
            $slider.append($('<img class="slide hidden current" data-src="'+ img.image +'">'));
          } else {
            $slider.append($('<img class="slide hidden" data-src="'+ img.image +'">'));
          }
        });
        loadProductImages();
      }
    },
    complete: function() {
      $colors.prop('disabled', false);
    }
  });
});
$(document).on('click', 'body', function (e) {
  var $filters = $('.page.catalog .content_filters .filter');
  if ($(e.target).hasClass('filter') || $filters.has(e.target).length) return;
  $filters.removeClass('shown');
});
$(document).on('click', '.page.catalog .content_pages .load-next-page', function() {
  var $load_next_page = $(this);
  $load_next_page.prop('disabled', true);
  var $content_pages = $load_next_page.closest('.content_pages');
  var $next_page_link = $content_pages.find('a.active').next();
  $.ajax({
    url: $next_page_link.attr('href'),
    type: 'get',
    success: function(res) {
      $products = $('<div>').append($.parseHTML(res)).find('.products .product');
      $('.page.catalog .products').append($products);
      $load_next_page.prop('disabled', false);
      $content_pages.find('a').removeClass('active');
      $next_page_link.addClass('active');
      if ($next_page_link.next().is('a') == false) {
        $load_next_page.remove();
      }
      loadProductImages();
    }
  });
});
