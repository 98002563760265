$(document).on('submit', '#email_rss form', function(e) {
  e.preventDefault();
  show_loader($('#email_rss'));
  $.ajax({
    url: $(this).attr('action'),
    type: $(this).attr('method'),
    dataType: 'html',
    data: $(this).serialize(),
    success: function (res) {
      $('#email_rss').html(res);
    }
  });
});